import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import { AuthContext } from "../context/authContext";
import postService from "../services/posts.service";
import alertService from '../services/sweetAlert';
import {  useCallback } from 'react'
import Drawer from 'react-bottom-drawer'
import profileService from "../services/profile.service";
import { RWebShare } from "react-web-share";

import Gifts from "./Gifts";
const context = new AudioContext();
const source = context.createBufferSource();
// console.log('hello')
dayjs.extend(relativeTime);

export default function Singleview({
  user,
  time,
  des,
  avater,
  postimage,
  postvideo,
  id,
  item,
  reactions,
  comment,
  showInput,
  mediaType
}) {
  const [timeLeft, setTimeLeft] = useState(30); // Timer starts at 30 seconds
  const [isAnswerLocked, setIsAnswerLocked] = useState(false);
  const navigate = useNavigate();
  const relativeTimeString = dayjs(time).fromNow();
  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [userReaction, setUserReaction] = useState("");
  const [likes, setLikes] = useState(0);
  const [love, setLove] = useState(0);
  const [comments, setComments] = useState(0);
  const [postReactions, setPostReactions] = useState([]);
  const [exps, setExps] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [giftItem, setgiftItem] = useState(0); 
  const openDrawer = useCallback(() => setIsVisible(true), [])
  const [showAnswers, setShowAnswers] = useState(false);
  const [answers, setAnswers] = useState([]);
  const closeDrawer = useCallback(() => {
    setIsVisible(false);
  }, []);

 
 // const [on, toggle] = useState(false);

 const [playing, setPlaying] = useState(null);

 //const { organizeComments } = useContext(PostsContext)
 const cdn = 'https://files.geetsuhane.com/';
 //console.log(item);
 //var music = new Audio(null);
 //var song = new Audio(null);

 const handleShowAnswers = async () => {
  setShowAnswers(!showAnswers);

  if (!showAnswers) {
    try {
      // Fetch answers from the server
      const response = await profileService.getResult({
        qry: `CALL sp_quiz('${currentUser?.SID}', ${item?.id}, '', 0, 'show')`,
      });

      setAnswers(response?.[0]);
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
  }
};

const handleApproveAnswer = async (answerId) => {
  try {
    const response = await profileService.getResult({
      qry: `CALL sp_quiz('', ${answerId}, '', 0, 'approve')`,
    });

    alertService.success("Answer approved successfully!");
    
    // Optionally update the UI
    setAnswers((prev) =>
      prev.map((ans) =>
        ans.id === answerId ? { ...ans, approved: true } : ans
      )
    );
  } catch (error) {
    console.error("Error approving answer:", error);
    alertService.error("Failed to approve answer.");
  }
};


 async function fetchAudio(name) {
  try {
    let rsvp = await fetch(`${name}`);
    return context.decodeAudioData(await rsvp.arrayBuffer()); // returns a Promise, buffer is arg for .then((arg) => {})
  } catch (err) {
    console.log(
      `Unable to fetch the audio file: ${name} Error: ${err.message}`,
    );
  }
}
const quiz = async (action) => {
  let ans = '';
  if (!currentUser) {
    return;
  }

  if (action === 'answer') {
    try {
      const text = await alertService.alertInput('Enter your answer'); // Await user input
      if (text?.length > 0) {
        console.log("text: " + text);
        ans = text;
      } else {
        alertService.error('No input provided'); // Notify user about empty input
        return;
      }
    } catch (err) {
      console.error('Alert closed without input:', err);
      alertService.error('You closed the input dialog without providing an answer.');
      return;
    }
  }

  console.log(item?.id);
  try {
    const res = await profileService.getResult({
      qry: `CALL sp_quiz('${currentUser?.SID}', ${item?.id}, '${ans}', 0, '${action}')`,
    });

    console.log(res?.[0][0]?.res);
    alertService.info('Quiz', res?.[0][0]?.res);
  } catch (error) {
    console.error('Error fetching quiz result:', error);
    alertService.error('Error processing the quiz.');
  }
};


 const playSong = async(pid) => {
   console.log(playing);
   try{
       if( playing===true) {
         //music.pause();
         source.pause();
         setPlaying(false);
         return;
       }
       setPlaying(true)

       var songs =  profileService.getResult({
         qry: `CALL sp_getMusic(${pid})`
       }).then(songs => {
          //music = new Audio(songs?.[0][0]?.musicUrl);
          //song = new Audio(cdn+'users/'+ songs?.[0][0]?.songUrl);
         //console.log(cdn+'users/'+ songs?.[0][0]?.songUrl)
         fetchAudio(cdn+'users/'+ songs?.[0][0]?.songUrl).then(buffer => source.buffer=buffer);
         const biquadFilter = context.createBiquadFilter();
        biquadFilter.type = 'lowshelf';
        // biquadFilter.frequency.setValueAtTime(200, context.currentTime + 1);
         
       //console.log(audioBuffer)				

       source.connect(biquadFilter);
       biquadFilter.connect(context.destination);

      //  var compressor = context.createDynamicsCompressor();
      //  biquadFilter.connect(compressor);
      //  compressor.connect(context.destination);
       source.start();
         //music.play();
         //song.play();
         setPlaying(true)
         //music.maxDistance=40;
         source.addEventListener("ended", function(){
           source.currentTime = 0;
           //music.pause();
           console.log("ended");
           setPlaying(false)
         });
       }
         
     )
   } catch(ex) {
     console.log(ex);
     alertService.info(
       'Error',
       'Apologies, unable to play this song');
       setPlaying(false);
   }
  }
 const updateStats = async(pid) => {
  //console.log('called');
  if(currentUser) {
    var res = await profileService.getResult({
      qry: `CALL sp_insertShare(${pid},'${currentUser.id}') `
  });
  }
  
  }
    const [state, setState] = useState({
      isPaneOpen: false,
      isPaneOpenBottom: false,
    });



    const handleSubmitAnswer = () => {
      if (isAnswerLocked) {
          alertService.error('Time is up',"Time is up! You can't submit your answer.");
          return;
      }
      quiz('answer');
    };
 
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const deletePostAction = async () => {
    const response = await postService.deletePost(id);
    //console.log(item);
    if (response) {
      setIsDeleted(true);
      setIsOpen(false);
    }
  };

 const sendGift = (item,uid) => {
  openDrawer();
  localStorage.setItem("item", item);
  //localStorage.setItem("giftuser", usr);
  localStorage.setItem("giftsource", 'post');
  localStorage.setItem("uid",uid);
  setState({ isPaneOpenBottom: true });
 }
//  const openShare = (soon) => {
//   const success =  alertService.info(
//     soon,
//     'Coming soon!'
//   )
// }
const getExps = async (itemid) => {     
  
  var exps = await profileService.getResult({
      qry: `CALL sp_getExps(${itemid});`
  }); 
  setExps(exps?.[0][0]?.totalExps);
  // console.log(exps)
}

const highlightPattern = (text) => {
  //if(text?.includes('/recording')) return;
  //console.log(text)
  const pattern = '\#(.*?)\#';
  const splitText = text?.split(pattern);
  //console.log(splitText)
  if (splitText?.length ==0 || !text) 
    return text;
  else {
    const matches = text?.match(pattern);
    //console.log(matches);
    const clink = `/moments?search=${matches?.[1]}`;
    return text?.replace(matches?.[0],`<a href=${clink}>`+matches?.[0]+'</a>')
   
  } 
}

  const reactToPost = async (reaction) => {
  
    if (currentUser?.id) {
      var updateLikes =  profileService.getResult({
        qry: `CALL sp_updateLikes('${currentUser?.id}', ${id})`
      }).then( ret => {
        // console.log(ret)
        if( ret?.[0][0]?.res===1)
          setLove(love+1);
      } )
      return;
    } else {
      navigate("/login");
    }
    
  };

  // const queryParams = new URLSearchParams(window.location.search)
  // const postId = queryParams.get("postId")

  useEffect(() => {
    if (item ) {
      getExps(item?.id);
    //console.log(item)
      setLove(item?.likes);
      var commentsCount= item?.comments;
 
      //console.log(commentsCount)
      try{
        setComments(commentsCount>0?commentsCount:"")
        if (item?.status==100 && currentUser?.id !== item?.userId) {
          setTimeLeft(30);
          quiz('entry');
        
        if (timeLeft > 0 && !isAnswerLocked) {
          const timer = setInterval(() => {
            setTimeLeft((prev) => prev - 1);
          }, 1000);
    
          return () => clearInterval(timer); // Cleanup on component unmount or timer reset
        }
        
        if (timeLeft === 0) {
          setIsAnswerLocked(true); // Lock the answer when time runs out
        }

    }
      } catch (e) {

      }
     
    }
  }, [item, currentUser, giftItem]);
  //commentsFn();
  return (
    
    <React.Fragment>
     <div className={`px-3`}>
      {!isDeleted ? (
        <div className="card w-100 shadow-xss rounded-xxl border-0 p-2 mb-3" >
            {item?.status==100 && 
             <div >         
              <div className="font-xsssss text-blue p-1 strong text-center">
               <Link className="p-2" to="/search?searchBy=top10B">Top 10 Brainers</Link> 
               <Link  className="p-2" to="/search?searchBy=top10D">Top 10 Drainers</Link> 
                </div>       
                <div className="font-xsssss strong text-center">Top 10 Brainers and Drainers will receive 10 GSC Daily</div>      
             <img className="rounded-3 w-100" src= {cdn+"home/bd.gif"}></img>         
             <div class="bg-mini-gradiant text-white text-center font-xsssss">Time Left: {timeLeft} seconds</div>  
             
            </div>
      }
           {item?.status!=99 &&
          <div className="card-body p-0 d-flex position-relative">
          <a  href={`/userpage?uid=${item?.sid}`}>
            <figure className="avatar me-3">
              {avater ? (
                <img
                  src={avater}
                  alt="avater"
                  className="shadow-sm rounded-circle w45"
                />
              ) : (
                <div className="align-items-center bg-grey d-flex justify-content-center rounded-img-sm">
                  <span className="ti-user font-sm"></span>
                </div>
              )}
            </figure></a> <a  href={`/userpage?uid=${item?.sid}`}>
            <h4 className="fw-700 text-grey-900 font-xssss mt-1">
              {" "}
              {user}{" "}
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                {" "}
                {relativeTimeString}
              </span>
            </h4></a>

            {(item?.userId === currentUser?.id || currentUser?.SID === "008")
               && (
                <React.Fragment>
                  <div
                    className={`ms-auto pointer ${isOpen ? " show" : ""}`}
                    id="dropdownMenu4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={toggleOpen}
                  >
                    <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
                  </div>
                  <div
                    className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg  ${
                      isOpen ? " show" : ""
                    }`}
                    aria-labelledby="dropdownMenu4"
                  >
                    <div
                      onClick={deletePostAction}
                      className="card-body p-0 d-flex"
                    >
                      <i className="feather-trash text-grey-500 me-3 font-lg"></i>
                      <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
                        Delete Post{" "}
                        <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                          Delete Post
                        </span>
                      </h4>
                    </div>
                  </div>
                </React.Fragment>
              )}
          </div> }
          {postvideo ? (
            <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die pointer"  onClick={()=>{
              //localStorage.setItem('postId',item.id)
              navigate(currentUser?.id ? `/comments?postId=${item.id}` : "/login")
            }}>
              {/* <a href="/defaultvideo" className="video-btn">
                <video autoPlay loop className="float-right w-100">
                  <source src={`${postvideo}`} type="video/mp4" />
                </video>
              </a> */}
              <video
                src={postvideo + "#t=0.001"}
                type="video/mp4"
                width="100%"
                height="100%"
                controls
              ></video>
            </div>
          ) : (
            ""
          )}

          <div className="card-body p-0 me-lg-5">
            <div className="fw-500 text-black-500 lh-26 font-xsss w-100 mb-2">
              
            { item?.mediaType==='gif'?
              <img src={des}></img>:
              
              <div dangerouslySetInnerHTML={{ __html: highlightPattern(des) }}></div>

              } {  item?.mediaType==='song' &&
              <div class="container">
              
              <a onClick={() => playSong(item?.id)}>
                  <img 
                  src={avater} 
                  className="feather-youtube "
                  width="100%"
                  height="100%" 
                  
                  />
               <i className={`btn ${playing?'feather-pause':'feather-youtube'} text-white font-xxl`}></i>
                            
              </a>
              </div>
              
              }
          
              {/* <a href="/defaultvideo" className="fw-600 text-primary ms-2">
                {des?.length > 100 ? "See more" : ""}
              </a> */}
            </div>
            {mediaType==='Youtube' &&
         <div className="card-body d-block p-0 mb-3">
                  <iframe width={"100%"} height={"450px"}
        src={`https://www.youtube.com/embed/${item?.mediaUrl}`} allowfullscreen>
        </iframe>
          </div>
        }  
         {mediaType==='Insta' &&
              <div className="card-body d-block p-0 mb-3">
                        <iframe  width={"100%"} height={"450px"}
              src={`${item?.mediaUrl}`} allowfullscreen>
              </iframe>
                </div>
         }  
          </div>
          {postimage?.length ? (
            <div className="card-body d-block p-0 mb-3 pointer"  onClick={()=>{
              //localStorage.setItem('postId',item.id)
              navigate(currentUser?.id ? `/comments?postId=${item.id}` : "/login")
            }}>
              <div className="row ps-2 pe-2">
                <div className="col-sm-12 p-1">
                {item?.status==99 ?
                 <div className="box-shadow: 0 0 0 250px #522d5b">
                  <div   style={{position:"relative", backgroundImage:`url("https://files.geetsuhane.com/home/hbady.gif")`,backgroundRepeat:"no-repeat",backgroundPosition:"center", height: "155px"}}  >  </div>
                  <div className="text-center">
                    <img src={postimage} className="text-center rounded-3 w-75" alt={item?item.text:"post"} />    
             
                  </div>
                 </div>
                 : 
                  <img src={postimage} className="rounded-3 w-100" alt="post" />
                }
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {item?.status==100 && currentUser?.id !== item?.userId && currentUser &&
             <div class="justify-content-right">                                
           
             <div onClick={()=>handleSubmitAnswer()}  className="rank-button bg-secondary  text-center text-white font-xsssss m-1 ">Submit Answer </div>
             
              
                {isAnswerLocked && (
                  <p class="p-1 text-red font-xssss">Time is up or you have submitted your answer!</p>
                )}
              <div>

              </div>
            </div>
      }
      {item?.status==100 && currentUser?.id == item?.userId && currentUser &&
        <div onClick={()=>handleShowAnswers()}  className="rank-button bg-secondary  text-center text-white font-xsssss m-1 ">Show Answers </div>
      }
      {showAnswers && (
        <div className="answers-list">
          {answers?.length > 0 ? (
            answers?.map((answer) => (
              <div
                key={answer?.id}
                className="answer-item d-flex align-items-center my-2 p-2 border"
              >
                <a  href={`/userpage?uid=${answer?.SID}`}>
            <figure className="avatar me-3">
              {answer?.picture ? (
                <img
                  src={answer?.picture.includes('googleusercontent')?answer?.picture:cdn + 'users/' + answer?.picture}
                  alt="avater"
                  className="shadow-sm rounded-circle w45"
                />
              ) : (
                <div className="align-items-center bg-grey d-flex justify-content-center rounded-img-sm">
                  <span className="ti-user font-sm"></span>
                </div>
              )}
            </figure></a> 
                <div className="answer-details">
                  <p className="mb-1">
                    <strong>{answer?.name}:</strong> {answer?.txt}
                  </p>
                  {!answer.approved && (
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleApproveAnswer(answer?.id)}
                    >
                      Approve Answer
                    </button>
                  )}
                  {answer.approved && (
                    <span className="badge bg-success">Approved</span>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>No answers found.</p>
          )}
        </div>
      )}
  

    
          <div className="card-body d-flex p-0">
            <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
              {/* <i
                onClick={() => {
                  reactToPost("like");
                }}
                className={`feather-thumbs-up text-white bg-primary-gradiant me-1 btn-round-xs font-xss ${
                  userReaction === "like" && "reacted"
                }`}
              ></i>
             {likes!=0?likes:""} <span style={{marginLeft:"10px"}}></span> */}
              <i
                onClick={() => {
                  reactToPost("love");
                }}
                className={`feather-heart text-white bg-red-gradiant me-2 btn-round-xs font-xss  ${
                  userReaction === "love" && "reacted"
                }`}
              ></i> 

              

              {/* {postReactions.filter((item) => item.reaction === "love")
                .length || ""} */} {love?love:""} <span style={{marginLeft:"10px"}}></span>
            </div>
    
            <div
            onClick={()=>{
              //localStorage.setItem('postId',item.id)
              navigate(currentUser?.id ? `/comments?postId=${item.id}` : "/login");showInput(true);
            }}
              className="d-flex cursor-pointer align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"
            >
              <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
              <span className="d-none-xss"> </span> {comments!=0?comments:""}
            </div>

            <div  className="d-flex cursor-pointer align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"
            style={{paddingLeft:"10px"}}  onClick={()=>{sendGift(item.id,item.userId)}}>     <i className="feather-gift text-grey-900 font-sm btn-round-md bg-greylight"></i></div>
             <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"> { exps}</div>
             <div
              className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss`}
              id={`dropdownMenu${id}`}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              // onClick={()=>{openShare('Sharing')}}
            >
              {/* <i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i> */}
             
              <RWebShare
                data={{
                    text: user +''+ item?.text.replace(/<[^>]+>/g, ''),
                    url: "https://geetsuhane.com/comments?&postId="+item?.id,
                    title: "Link share",
                }}
                onClick={() => updateStats(item?.id)}
            >
                 <i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i>
                 
            </RWebShare>
            </div>
            <div
              className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0`}
              aria-labelledby={`dropdownMenu${id}`}
            >
              <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center" >
                Share{" "}
                <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2"></i>
              </h4>
              <div className="card-body p-0 d-flex">
                <ul className="d-flex align-items-center justify-content-between mt-2">
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-facebook">
                      <i className="font-xs ti-facebook text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-twiiter">
                      <i className="font-xs ti-twitter-alt text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-linkedin">
                      <i className="font-xs ti-linkedin text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-instagram">
                      <i className="font-xs ti-instagram text-white"></i>
                    </span>
                  </li>
                  <li>
                    <span className="btn-round-lg pointer bg-pinterest">
                      <i className="font-xs ti-pinterest text-white"></i>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="card-body p-0 d-flex">
                <ul className="d-flex align-items-center justify-content-between mt-2">
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-tumblr">
                      <i className="font-xs ti-tumblr text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-youtube">
                      <i className="font-xs ti-youtube text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-flicker">
                      <i className="font-xs ti-flickr text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-black">
                      <i className="font-xs ti-vimeo-alt text-white"></i>
                    </span>
                  </li>
                  <li>
                    <span className="btn-round-lg pointer bg-whatsup">
                      <i className="font-xs feather-phone text-white"></i>
                    </span>
                  </li>
                </ul>
              </div>
              <h4 className="fw-700 font-xssss mt-4 text-grey-500 d-flex align-items-center mb-3">
                Copy Link
              </h4>
              <i className="feather-copy position-absolute right-35 mt-3 font-xs text-grey-500"></i>
              <input
                type="text"
             
                className="bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg"
              />
            </div>
            <div >
              
          <center>

      <Drawer
        duration={250}
        hideScrollbars={true}
        onClose={closeDrawer}
        isVisible={isVisible}
      >
          <div className="fw-700 text-grey-900 font-xsssss mt-1"> <img style={{width:"12px",height:"12x"}} src={avater}></img> {user}</div>

          <Gifts giftItem={giftItem} setgiftItem={setgiftItem} ></Gifts>
         
      </Drawer>
     
      </center>
    
      </div>      
     
          </div>
          
         
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
  </div>
    </React.Fragment>
  );
}
