import React, {Fragment,useContext,useEffect,useState,useRef } from "react";
import profileService from "../../services/profile.service";
import { AuthContext } from "../../context/authContext";
import { Link, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
import AudioPlayer from '../../components/chat/audioPlayer';
import alertService from '../../services/sweetAlert';
import GiphyGif from '../../components/GiphySearch';
import Ratings from "../Ratings";
import Swiper from "../Swiper";
import Youtube from "../Youtube";
import Avatar from '@mui/material/Avatar';
import useSound from 'use-sound'; 



export default function  ChatDemo() {
  

    //const cdn = 'https://files.geetsuhane.com/';
    const cdn = 'https://files.geetsuhane.com/';
    const [loading, setLoading] = useState(false);
    const [chat,setChat] = useState([]);
    // const [audit,setAudit] = useState(null);
    const { currentUser, sendMessageToGroup } = useContext(AuthContext)
    const [taguser, setTaguser] = useState('')
    const [content, setContent] = useState('')
    const navigate = useNavigate()
    const [inactiveMembers, setInactivemembers] = useState(null);
    const [multi, setMulti] = useState(null);
    const [activeMember, setActiveMember] = useState(null);
    const [swipeDiv, setSwipeDiv] =useState(0);
    const [playDhol] = useSound(cdn+'sound/dhol.mp3');
    const [playSehnai] = useSound(cdn+'sound/sehnai.m4a');

    

    const redirectgame = () => {
      navigate('/game');
      return;
    }

   

    const getChatDemo = async (gtxnid) => {
        var chatData = await profileService.getResult({
            qry: `CALL sp_getChatDemo();`
        });
        setChat(chatData?chatData[0]:'');
        //console.log(chatData[0])
    }

    const SubmitHandler = async (categoty,msg) => {
      if(currentUser==null)
      {
      localStorage.setItem("redirect", "/");
      navigate('/login');
      return;
      };
      sendMessageToGroup ({
        message: msg,
        senderId: currentUser.id,
        name: currentUser.name,
        picture: currentUser.picture,
        receiverId: 2,
        groupName : 'Activity',
        time: new Date(),
        msgType: categoty,
        sender: currentUser
      });
        setTaguser('');
        setContent('');
    }
 
    const handleNavigation = async(link) => {
      //playDhol();
      // if(link==="/boatrace") playSehnai();
      if(link==="/bd") {
        var res = await profileService.getResult({
          qry: `CALL sp_quiz('${currentUser?.SID}',0,'',0,'latest');`
      }).then (res => {
      console.log(res?.[0][0]?.pid)            
      link = '/comments?&postId='+res?.[0][0]?.pid;
    })
      }
      navigate(link);
    };

      const sendGif = p => {
        SubmitHandler('gif',p);
      }

    // const getAuditdata = async() => {
    //       var auditTitle = await profileService.getResult({
    //         qry: `select s.id,s.title,question from survey s where s.status=1 limit 10;`
    //     });
    //     setAudit(auditTitle);
    //     // console.log(auditTitle)
       
    //   }

      // const submitSurvey = async(sid, ans,stitle) => {
      //   const confirmed = await alertService.confirm(
      //     'Survey',
      //     'Are you sure you want to submit this answer - ' + ans + ' ?'
      //   )
      //     if(confirmed) {           
      //       var submitAns = await profileService.getResult({
      //           qry: `CALL sp_insertSurveyResponse('${currentUser?.id}',${sid},'${stitle}');`
      //       }).then (res => {
      //       console.log(res?.[0][0]?.resp)            
      //       alertService.info(res?.[0][0]?.resp);
      //       })
      //   }
      // }

      // const logAudit =async () => {
      //   const res = await profileService.auditData({
      //       userid: currentUser.id, activity: 'Clicked on ChatDemo'
      //       });
      // }
    
      const handleSwipe = async () => {
        // console.log(swipeDiv);
        setSwipeDiv(swipeDiv==0?1:(swipeDiv==1?2:0))
      }

      const games =["https://files.geetsuhane.com/home/gscafe.gif","https://files.geetsuhane.com/home/unprotected.jpg","https://files.geetsuhane.com/home/gsp-s.gif"];

    useEffect(() => {
        async function fetchList() {
            // const interval = setInterval(() => {
            //      getChatDemo();
            //   }, 15000);
              if(!loading) {
                //console.log(loading);
                //await getActiveMembers();
                // await getChatDemo();
                // await getInactivecrew();
             }
              
              setLoading(true);
              // return () => clearInterval(interval);
       
         
          // await updatetime();
        }
        fetchList();
        
         //getAuditdata();

        //  function onFullscreenChange() {
        //   setIsFullscreen(Boolean(document.fullscreenElement));
        // }
              
        // document.addEventListener('fullscreenchange', onFullscreenChange);
      
        // return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
        
      }, []);

      // useEffect(() => {     
     
      //    if(activity) {
      //     if(chat.length>4) {
      //       chat.shift();
      //     }         
      //     setChat([...chat, activity]);
      //    }
        

       
      // }, [activity]);

        return (
            <Fragment>
            {/* {windowWidth > 1000 && <ChatUsers title='Participants' />} */}
            <div className="heme-dark-bg p-2 w-100">
            
           
            {currentUser? (
                <h6 className="d-flex justify-content-center">Welcome {currentUser.name} 🤗🤗</h6>
            )
            :""}
                  <div  className="card w-100 shadow-xss rounded-xxl border-0 p-1 mb-3" >
                    
                   

                           {/* <div className='messages-content pb-3'> 
                            {chat?.length 
                              ? chat?.map(
                                  (
                                    {
                                      id,
                                      message,
                                      senderid,
                                      picture,
                                      name,
                                      createdAt = new Date(),
                                      msgType,
                                      gname,
                                     sender
                                    }, 
                                    index
                                  ) => {
                                    return (
                                      <div  key={id}   >
                                 <div  className="card-body p-0 m-0 d-flex position-relative">                                
                                <a  href={`/userpage?uid=${sender?.SID}`}>
                                    <figure className="avatar me-0">
                                    {picture ? (
                                        <img
                                          className='rounded-img-sm mx-1'
                                          src={
                                            picture.includes('googleusercontent')?picture:
                                            cdn + "users/"+
                                            picture
                                          }
                                          alt=''
                                         
                                        />
                                      ) : (
                                        <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-3'>
                                          <span className='ti-user font-sm'></span>
                                        </div>
                                      )}
                                    </figure></a> 
                                   
                                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                                    {" "}
                                    <a  href={`/userpage?uid=${sender?.SID}`}>
                                    {name} 
                                    </a>
                                    <div className='message-wrap justify-content-between text-grey-500 align-items-center mt-0 pt-0 font-xsssss'>
                                        {msgType === 'audio' ? (
                                          <AudioPlayer url={message} />
                                        ) : (
                                          msgType==='gif'? <img src={message}></img>:
                                         
                                         <span className="d-block font-xssss fw-500 mt-1 lh-3 text-blue-500" dangerouslySetInnerHTML={{ __html: (message.replace('http','').replace('www','')) }}></span>
                                        )}
                                      
                                      </div>
                              
                                    <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                                        {" "}
                                        {createdAt?dayjs(createdAt).fromNow():''} 
                                        <span className="p-1">  </span>
                                        <a href={`/dw?uid=${sender?.SID}`}>  
                                        <span className="p-1 rounded-xxxl bg-black text-white font-xsssss"> dw </span> 
                                        </a>

                                       
                                    </span>
                                    </h4>
                                       
                                      </div>
                                      </div>
                                    )
                                  }
                                )
                              : <div>
                              
                                <img className="rounded-3 w-100 " src= {cdn+"promo/support1.gif"}></img>
                              </div>
                              }
              
       <div  className='chat-form'>  {  content?.length>2 && content?.length<15 &&
           <GiphyGif text={content}  onClick={sendGif}></GiphyGif> }
              <div className='form-group'> 
                      <input 
                        value={content}
                        onChange={e => {
                          setContent(e.target.value)
                        }}
                        type='text'
                        className='w-100 bg-grey'
                        placeholder={taguser?'Reply to '+taguser: 'Start typing..'}
                        autoFocus 
                      />
             
                    </div>
            <button type='submit' onClick={()=>SubmitHandler('text', content)} className={`bg-current`}>
                      <i className='ti-arrow-right text-white w100'></i>
                    </button>
                    
            
          </div>
              
                          </div>  */}
                  <div>
                   
                  </div>
                          <div className="pt-1" >
                    </div>        
                           <div className="text-center w-100 ">  

                          
 <div  className="card w-100 shadow-xss rounded-xxl border-0 p-1 mb-3 text-center" >
  <Link to="/va">
  <div className="text-center text-navy font-xsss p-2 strong rounded-xl"> Lets Try Sargam and Win Points!</div></Link>
                            {/* <div className="font-xsssss d-flex justify-content-center text-green mt-1 p-2 strong">If your line is selected, you will be rewarded GSC in your GS account everytime!</div>   */}
                  
               
                    <div>
                    <Link to="/sponsor?stype=vip" className="feather-award bg-red-gradiant text-center text-white font-xssss fw-600 p-2 w65 rounded-3 d-inline-block">VIP</Link>
                    <span className="p-1"></span>    

                            <Link to="/compose" className="feather-music bg-gold-gradiant text-center text-white font-xssss fw-600 p-2 w65 rounded-3 d-inline-block">Join</Link>
                      <span className="p-1"></span>     
                       <Link to="/va" className="feather-mic bg-red-gradiant text-center text-white font-xssss fw-600 p-2 w65 rounded-3 d-inline-block">VA</Link>
                       <span className="p-1"></span>    
                       {currentUser &&
                         <Link to={`/gupshup/${currentUser?.gname}`} className="feather-message-square bg-primary-gradiant text-center text-white font-xssss fw-600 p-2 w65 rounded-3 d-inline-block">Chat</Link>
                       } 

                            </div>

                            <div className="d-flex" style={{justifyContent:'center'}}>
                             <Link to="/loka/bramh"> <Avatar  sx={{ width: 70, height: 70 }} src={cdn+'boatrace/bramh.png'} ></Avatar></Link>
                             <Link to="/loka/vishnu"> <Avatar  sx={{ width: 70, height: 70 }} src={cdn+'boatrace/vishnu.png'} ></Avatar></Link>
                             <Link to="/loka/shiv"> <Avatar  sx={{ width: 70, height: 70 }} src={cdn+'boatrace/shiv.png'} ></Avatar></Link>                            
                            </div>
 </div>
 <Youtube></Youtube>

 

          <div >
         
                <div onClick={()=>handleNavigation('/chess')}>                      
                      <img className="rounded-3 w-100" src= {cdn+"home/chess.gif"}></img>
               </div>
               <div className="p-1"></div>
               <div  onClick={()=>handleNavigation('/bd')}>                      
                      <img className="rounded-3 w-100" src= {cdn+"home/bd.gif"}></img>
               </div>
           
            <div  className="pt-2">
            <div onClick={()=>handleNavigation('/boatrace')}>
                         
                          <img className="rounded-3 w-100" src= {cdn+"boatrace/boatrace-promo.gif"}></img>
                          </div>
                        </div>
                        {/* <div className="bg-black font-xsss strong  text-white"> Ranking:                                                        
                      <Link to="/search?searchBy=dwTop50" className="m-3 text-yellow">Monthly Top 50 </Link>
                      <Link to="/search?searchBy=dwTop10" className="text-yellow">Daily Top 10 </Link>
                      </div> */}
                        <div>
                        <div onClick={()=>handleNavigation('/boatrace')}
                   className="text-center redoka-font bg-primary-gradiant pointer mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl  font-xsssss fw-700 ls-lg text-white"> Play</div>
                        </div>
                        <div className="bg-black text-white font-xssss"> <span className="text-yellow">Monthly Rewards Top 1 Team: </span> 
                        Daily Top 10 Players of winnning team: GSC 10 and  Trophies for top 3 Top Owner, Co-Owner, Lead and Player of the Series</div>
                      
 </div>
 


 <div className="pt-2">
  <div>
  <div className="bg-black font-xsss strong  text-white"> Ranking:                                                        
                      <Link to="/search?searchBy=dwTop50" className="m-3 text-yellow">Monthly Top 50 </Link>
                      <Link to="/search?searchBy=dwTop10" className="text-yellow">Daily Top 10 </Link>
                      </div>
  </div>
 <iframe className="w-100" height="310" src="https://www.youtube.com/embed/S-O2dD6hz-E?si=9P6OZ7CAvq2Q26_t" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
           
            <div  >
                          <Link to="/dw">
                          <img className="rounded-3 w-100" src= {cdn+"home/dw.gif"}></img>
                          {/* <div className="bg-black text-white font-xssss"> <span className="text-yellow">Monthly Rewards Top 1: </span> 
                            GSC 800, Top 2: GSC 500, Top 3: GSC 200, Top 4-50: GSC 100</div> */}
                          <div className="bg-yellow text-black font-xsssss">  Daily Top 10: GSC 10</div>
                          {/* <img className="rounded-3 w-100" src= {cdn+"home/dw.jpg"}></img> */}
                          </Link>
                        </div>
                        <div className="bg-black font-xsss strong  text-white"> Ranking:                                                        
                      <Link to="/search?searchBy=dwTop50" className="m-3 text-yellow">Monthly Top 50 </Link>
                      <Link to="/search?searchBy=dwTop10" className="text-yellow">Daily Top 10 </Link>
                      </div>
                        <div>
                        <Link to="/dw"
                   className="text-center redoka-font bg-primary-gradiant pointer mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl  font-xsssss fw-700 ls-lg text-white"> Play</Link>
                        </div>
                     
 </div>


      {/* <div  className="card w-100 shadow-xss rounded-xxl border-0 p-1 mb-3" >
      <div>
    <iframe className="w-100" height="215" src="https://www.youtube.com/embed/AS1NWn8Mo-g?si=IR2a0QsfT0-_Ll_o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
   </div>
    <div className="font-xsssss strong pt-2"> 
      <center>
          <div>
          <Link to="/planets">
          <Avatar alt='Virtual Planet'  sx={{ width: 75, height: 75 }} 
                  src={cdn+'planets/pics/vp2.gif'}></Avatar>
          <div><span className="text-black">Create your own virtual planet,  and become planet owner!</span>  <span className="text-red strong">Create Now!</span> </div>
       </Link>   </div>
      </center>
    
      
   
    </div>

    

  </div> */}
  <div></div>
                            
                         
                              </div>
                            
                              <div  className="card w-100 shadow-xss rounded-xxl border-0 p-1 mb-3" >
            <center>
       
        { activeMember &&
   
            <div  className="card-body p-1 m-0 d-flex position-relative ">
            {activeMember?.map((value , index) => (
                
               <div  style={{maxWidth:'170px'}}  key={index} className="col-sm-5 pe-2 ps-1 ">

            <a  href={`/userpage?uid=${value?value.SID:''}`}>
            <figure className="avatar me-1">
            {value?.picture ? (
                <img
                className='rounded-img-sm mx-1'
                src={
                    value?.picture.includes('googleusercontent')?value.picture:value.picture.includes('groups/')?cdn+value.picture:
                    cdn + "users/"+
                    value.picture
                }
                alt=''
                
                />
            ) : (
                <div className='align-items-center bg-grey d-flex justify-content-center rounded-img-sm mx-1'>
                <span className='ti-user font-sm'></span>
                </div>
            )}
            
            </figure></a> 
            
            <h4  className="font-xsssss"><span className="strong ">{value?.name}</span>
            <div> <Ratings uid={value?.SID} size="small"></Ratings></div>   
            <div  className='message-wrap justify-content-center text-red align-items-center mt-0 pt-0 '>           
            <img src="https://files.geetsuhane.com/home/points.png" alt="points" className="w10 rounded-3 " /> {value?.worth}                
            </div>            
            <div className='message-wrap justify-content-center text-orange align-items-center mt-0 pt-0 font-xssss'>            
          Super Active
          
        </div>
       
            <span  className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
            
                <Link to={`/search?groupDetail=${value?.gname}`} className="bg-secondary pointer p-1 font-xssss   text-white">   
                <i className="feather-globe btn-round-xssss bg-primary-gradiant me-1"></i>{value?value?.gname:''}  </Link>
            
            
            </span>
            </h4>
            
            </div>
           ))} 
          
            </div>
 
        }
      
        </center>
   
        </div>
                          </div> 
          <div className="pt-1 m-0" >
          <Swiper onSwipeLeft={handleSwipe} onSwipeRight={handleSwipe}>
            <div>
          <div className="bg-red-gradiant text-white font-xssss p-1 text-center"> Game Room <Ratings uid="hacker" type="game" size="small"></Ratings></div>         
          <div className="font-xsssss strong text-center">Swipe left or right or click for more games </div>
          <div   style={{position:"relative", backgroundImage:`url(${games[swipeDiv]})`,width:"100%", backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundPosition:"center", height:"425px"}}  > 
           
          </div>
  </div>

  </Swiper>
  <div className="text-center">
            <Link to="/game" className="text -center bg-primary-gradiant pointer feather-chrome mt-1 btn pt-2 pb-2 ps-3 pe-3 ms-1 ls-3 rounded-xl  font-xsssss fw-700 ls-lg text-white">Enter</Link>
            </div>
  </div>
           
                  </div>
        
            
            {/* <Popupchat /> */}
          </Fragment>
        );
}
