import React, { Component , Fragment } from "react";
class Comingsoon extends Component {
    render() {
        return (
                   <div className="main-content ">
                    
                    <div className="middle-sidebar-bottom">
                  
                        <div className="middle-sidebar-left pe-0">
                            <div className="main-content pt-0 bg-white ps-0 pe-0">
                            <img className="rounded-3 w-100" src="https://files.geetsuhane.com/home/cs1.gif"></img>
                            </div>  
                         </div>
                </div>
            </div>
        );
    }
}

export default Comingsoon;