import React, { Component , Fragment, useState , useContext, useEffect,useRef} from "react";
import Pagetitle from '../components/Pagetitle';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/authContext";
import profileService from "../services/profile.service";
import alertService from '../services/sweetAlert';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Avatar } from "@mui/material";
// import Flash from "../components/Flash";
import toastr from "../services/toastr.service";
import "toastr/build/toastr.min.css";
import useSound from 'use-sound';
import { NotificationContext } from "../context/notificationsContext";
import Chip from '@mui/material/Chip';
import ResetCountdown from "../components/ResetCountdown";


export default function Member(props) {
    const navigate = useNavigate()
    const { currentUser,getUserDetails,userData,clearCache  } = useContext(AuthContext);
    const {sendMessageToGroup,activity} = useContext(NotificationContext);
    const [singers, setSingers] = useState([]);
    const [dummy, setDummy] = useState([]);
    const [refresh, setrefresh] = useState(false);
    const [titles,setTitles] = useState([]);
    const [startVal, setStartVal] = useState(0);
    const signRef = useRef(null);
    const [loading,setLoading] =useState(false);
    const cdn = 'https://files.geetsuhane.com/';
    const [playSign] = useSound(cdn+'sound/sign.mp3');
    const [playLose] = useSound(cdn+'sound/lose.mp3');
    const [lastTapTime, setLastTapTime] = useState(0);

    var dummyList="";
    var term="";
    const queryParams = new URLSearchParams(window.location.search)
    const membersList = queryParams.get("members");
    if(membersList?.length>0 || queryParams.get("searchBy")) {
      localStorage.removeItem("searchkey");
    } else {
       term = queryParams.get("groupDetail");
    }
    
    
    const getSingers = async (searchterm,start) => {      
    const searchTypeVal = queryParams.get("groupDetail")?queryParams.get("groupDetail"):(queryParams.get("searchBy")?queryParams.get("searchBy"):'member');
    if(queryParams.get("searchBy")==='race') searchterm=queryParams.get("team");
      //console.log(searchterm)
    //queryParams.get("groupDetail");
      var singerslist = await profileService.getResult({
        qry: `CALL sp_searchData('${searchterm?searchterm:''}', '${searchTypeVal}',${searchterm?.length>0 || start===0?0:startVal})`
      }).then(ret=>{
        if(startVal===0 || searchterm?.length>0 || start===0) {
         // console.log(ret?.[0])  
          setSingers(ret?.[0])
          setDummy (ret?.[0]);
        } else {
          setSingers([...singers, ...ret?.[0]]);
          setDummy([...dummy, ...ret?.[0]]);
           
        }
        
        setStartVal(ret?.[0]?.length>0?startVal+30:0)    
      });
      
    }

    const getTitles = async() => {
      const titlesList = await profileService.getResult ({
        qry: `select title from Titles;`
      })
      //console.log(titlesList)
        if(titlesList) {
          setTitles(titlesList);
        }
    }

    const sendMessage = async (msg) => {
      sendMessageToGroup ({
        message: msg,
        senderId: currentUser.id,
        name: currentUser?.name,
        picture: currentUser.picture,
        receiverId: 2,
        groupName : 'Activity',
        time: new Date(),
        msgType: 'text',
        sender: currentUser
      })

    };

    const signDW = async(uname,usid,cost) => {
      const currentTime = Date.now();
      if (currentTime - lastTapTime < 1000) {
        return;
      }
      setLastTapTime(currentTime);

      const confirmed = await alertService.confirm(       
        'Are you sure you want to sign '+ uname + ' it will cost you '+  cost + ' ?'
      )
      if (confirmed) {
        await sendMessage('Just signed '+uname+' in  DreamWorld 🤩');
        setLoading(true);
          var res = await profileService.getResult({
            qry: `CALL sp_updateDW1('sign','${usid}','${currentUser?.SID}', ${cost})`
        });
         if(res?.[0]?.[0]?.failed==='failed') {
          alertService.error(
            'Not Enough Cash!',
            `you do not have enough cash to sign: `+uname
          );
          setLoading(false);
          playLose();
          await getSingers('',0);
            return;
         } else if(res?.[0]?.[0]?.exists==='exists') {
          alertService.error(
            'Not available',
            `Someone already signed `+uname
          )
          setLoading(false);
          await getSingers();
          playLose();
          return;
        } else if(res?.[0]?.[0]?.vip==='vip') {
          alertService.error(
            'VIP',
            `Only VIP members can sign `+uname
          )
          setLoading(false);
          await getSingers();
          playLose();
          return;
        } else if(res?.[0]?.[0]?.duplicate==='duplicate') {
          alertService.error(
            'Duplicate',
            `You have already signed `+uname
          )
          setLoading(false);
          await getSingers();
          playLose();
          return;
        }  else if(res?.[0]?.[0]?.max==='max') {
          alertService.error(
            'Max limit',
            `You have already signed max number of members`
          );
          playLose();
          setLoading(false);
          await getSingers();
            return;
          }  else if(res?.[0]?.[0]?.wait==='wait') {
            alertService.error(
              'Too early',
              `This member is just signed by someone, please try after 5 min`
            );
            playLose();
            setLoading(false);
            await getSingers();
              return;
           }  
          else {
            //  console.log(res?.[0][0])
            // alertService.info(
            //   'Succesful',
            //   `You have sucessfully signed `+uname +` and you received `+res?.[0][0]?.wealth + `. you have 5 minutes to perform after that anyone can sign.`
            // );
            // alertService.imgAlert(`Congratulations!`,`You have sucessfully signed `+uname +` and you received `+res?.[0][0]?.wealth + `. you have 5 minutes to perform after that anyone can sign.`,cdn+'icons/celebrate.gif','');
            playSign();
            toastr.success(`You have sucessfully signed `+uname +` and you received `+res?.[0][0]?.wealth );
       
           navigate('/dw/?celebrate=yes');
         }
         

      } 
       

    }

    const isBefore1530 = () => {
      const now = new Date();
      const currentHour = now.getUTCHours();
  
      // Check if current time is less than 15:30
      return currentHour < 16 ;
    };

    const joinrace = async (actiontype) => {
      const confirmed = await alertService.confirm(
        'Are you sure you want to '+ actiontype +'?')
        if (confirmed) {
          var res = await profileService.getResult({
            qry: `CALL sp_boatRace('${currentUser?.SID}','exit','${currentUser?.gname}',0)`
          }).then(r => {
            console.log(r?.[0][0]?.res)
             if(r?.[0][0]?.res===1)  {
              alertService.success('Exit', 'You have successfully exited the race!');       
              const searchterm =term?term:(localStorage.getItem("searchkey")!='null'?localStorage.getItem("searchkey"):'');
               getSingers(searchterm);
             }
             
              else if(r?.[0][0]?.res===0)
                alertService.error('Error', 'You have already exited the race!');
            });
          }
      }
    
    

    const adduser = async(actiontype,uid) => {
      if(currentUser==null)
      {
        localStorage.setItem("redirect", "/search")
        navigate('/login')
      } 
        // console.log(actiontype)
        const confirmed = await alertService.confirm(
            actiontype=='add?add to:remove from'+ ' your group ',
            'Are you sure you want to '+ actiontype  + '?'
          )
          if (confirmed) {
            var res = await profileService.getResult({
                qry: `CALL sp_addToGroup('${actiontype}','${currentUser?.id}','${uid}', 0)`
            });
            if(res?.[0][0]?.res==='exists') {
                    // console.log(res[0]);
                    // const group = res[0][0].gname;
                    alertService.error(
                        'Error!',
                        `Ths member is already a member of planet `
                      )
            } else if(res?.[0][0]?.res==='soon') {
                      //console.log(res[0]);
                     
                      alertService.error(
                          'Error!',
                          `you need to wait atlast 96 hours`
                        )
                        return;
            } else {

              if (actiontype==='remove' && res?.[0][0]?.res===0 ) {
                alertService.error(
                  'Error!',
                  `You can not remove this member`
                )
                return
              }
                const msgtype = (actiontype==='remove'?'removed from group':'Congratulations');
                const msg = (actiontype==='remove'?'removed':'added');
              
                if(actiontype==='add') {
                  alertService.info(
                    msgtype, 
                    'You have sucessfully added this member in approval queue, please wait for approval!'
                  )
                } else {
                  alertService.info(
                    msgtype, 
                    'You have sucessfully '+msg+'  this member!'
                  )
                }
                
                //   if(actiontype==='join') {
                //     navigate('/gupshup?groupChat=group');
                //   }
                //await getSingers(searchterm);
            }
            const searchterm =term?term:(localStorage.getItem("searchkey")!='null'?localStorage.getItem("searchkey"):'');
            await getSingers(searchterm);
          }

    }

    const banUser = async(uid,uname) => {    
      if(currentUser==null)
      {
        localStorage.setItem("redirect", "/search")
        navigate('/login')
      } 
      const confirmed = await alertService.confirm(       
          'Are you sure you want to ban '+ uname + '?'
        )
        if (confirmed) {
          var res = await profileService.getResult({
              qry: `CALL sp_banUser('${uid}','${currentUser?.id}')`
          });        
                  alertService.success(
                      'Success!',
                      `Ths member has been banned now!`
                    )          
          setrefresh(true);
        }

  }


  const updateaTitle = async(uid,t) => {    
    console.log(uid);
    if(currentUser==null)
    {
      localStorage.setItem("redirect", "/search")
      navigate('/login')
    } 
        var res = await profileService.getResult({
            qry: ` CALL sp_updateTitle('${uid}','${currentUser?.SID}','${t}','${currentUser?.gname}')`
        });   
        if (res?.[0]?.[0]?.res===1) { 
          alertService.info('Update','Title has been updated');
          const audit = await profileService.auditData({
            userid: currentUser.id, activity: "updated title "+t+" for "+uid
            });
            clearCache(uid);
        } else if (res?.[0]?.[0]?.res===1) { 
          alertService.error('Failed','You can not update thise users title');
          return;
        } else {
          alertService.error('Failed','Seems you have already used all titles');
        }              
        setrefresh(true);

      }

      const auctionUpdate = async(uid,cost,typ) => {    
       // console.log(uid);
        let confirmed=false;
        if(typ==='allow') {
             confirmed = await alertService.confirm(
              'Confirm',
              'Are you sure you want to sell your activity points?' 
          ) } else {
      
           confirmed = await alertService.confirm(
              'Confirm',
              'Are you sure you want to spend '+cost+' GSC?' 
          ) }
        if (!confirmed) {
            return;
        }
            var res = await profileService.getResult({
                qry: ` CALL sp_Auction1('${uid}','${currentUser?.id}','${currentUser?.SID}',${cost},'${currentUser?.gname}','${typ}')`
            });   
            if (res?.[0]?.[0]?.res==='success') { 
              if(typ==='hire') {
                alertService.info('Success','You have successfully hired this member, but please keep in mind that someone else can hire if they pay more');
              } else if(typ==='allow') {
                alertService.info('Success','You are aligible for activity points transfer now');
              } else if(typ==='transfer') {
                alertService.info('Success','You have successfully transfered activity points, but please keep in mind that someone else can transfer if they pay more');
              }             
              await getSingers('',0);
              return;
            } else if (res?.[0]?.[0]?.res==='closed') { 
              alertService.error('Failed','Auction is closed now');
              return;
            } else if (res?.[0]?.[0]?.res==='not') { 
              alertService.error('Failed','Your planet is not eligible to hire this member today');
              return;
            } else if (res?.[0]?.[0]?.res==='balance') { 
              alertService.error('Failed','You do not have enough GSC to hire this member');
              return;
            } else if (res?.[0]?.[0]?.res==='low') { 
              alertService.error('Failed','It seems your bid is lower than the current bid');
              return;
            } else if (res?.[0]?.[0]?.res==='max') { 
              alertService.error('Failed','Your planer has already hired the maximum number of members, only 5 external members can be hired in a day');
              return;
            } else {
              alertService.error('Failed','Seems you have already used all titles');
            }              
           
    
          }

    useEffect(() => {
        async function fetchSingers() {
            //console.log(localStorage.getItem("searchkey"))
           
         const searchterm =term?term:(localStorage.getItem("searchkey")!='null'?localStorage.getItem("searchkey"):'');
          await getSingers(searchterm);
          // setrefresh(false);
          //await getTitles();
        };

      
       
        fetchSingers();
       
        // getUserDetails(currentUser?.SID);
      }, []);

  

      const searchChange = p => {          
        if(p.length>0) {
            //localStorage.setItem("searchkey",p);
            if(term) {
                setSingers(
                    singers.filter(singer => {
                      return singer.uname.toLowerCase().includes(p.toLowerCase())
                    })
                  )
            } else {
                getSingers(p);
            }
           
           
          
            
        } else {
            //console.log(dummyList);
            setSingers(dummy);
        }
       
      }

        return (
            <Fragment> 

                <div className="main-content right-chat-active">
                    
                    <div className="middle-sidebar-bottom">
                  
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                            
                                <div className="col-xl-12">
                                {(queryParams.get("searchBy")!="dwTop50" && queryParams.get("searchBy")!="dwTop10") ? (
                                    <Pagetitle header="Search" title={term?term:'Members'} text={term?term:(localStorage.getItem('searchkey')!='null'?localStorage.getItem('searchkey'):'')} onChange={searchChange}/>
                                 ) : (
                                  <div className=" text-center strong bg-mini-gradiant pointer p-2 font-xsss text-white">{queryParams.get("searchBy")=="dwTop50"?"Top 50 Monthly DW Stars": "Top 10 daily DW Stars"} </div>
                                  
                                )}
                                  {queryParams.get("searchBy")==="auction" &&
                                  <div className="bg-black text-white" >
                                  <ResetCountdown props="auction" /> 
                                    </div>
                                }
                                    <div className="row ps-2 pe-2">

                                        {singers?.map((value , index) => (
                                        <div key={index} className="col-md-3 col-sm-4 pe-2 ps-2 ">
                                        
                                            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                            
                                                <div className="card-body d-block w-100 ps-3  pe-3 pb-4 text-center"> 
                                              
                                                
                                                <a href={`/userpage?uid=${value?.SID}`}>
                                                    {/* <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1"><img src={value.imgpath?(value.imgpath.includes('googleusercontent')?value.imgpath:'https://files.geetsuhane.com/users/'+value.imgpath):`assets/images/user.png`}  alt="avater" className="float-right p-0 bg-white rounded-circle w-100 shadow-xss" /></figure> */}
                                                    <center>
                                                    <Avatar  sx={{ width: 80, height: 80 }} src={value?.imgpath?(value?.imgpath.includes('googleusercontent')?value.imgpath:'https://files.geetsuhane.com/users/'+value.imgpath):`assets/images/user.png`} ></Avatar>
                                                    </center>
                                                    <div className="clearfix w-100"></div>
                                                    <h4 className="fw-700 font-xsss mt-3 mb-0 text-center">{value?.uname?.substring(0,21)} </h4>
                                                    </a>
                                                    {value?.vip === 1 &&
                                                    <div>
                                                      <img
                                                        src="https://files.geetsuhane.com/icons/vvip-8.gif"
                                                        alt="VIP Icon"
                                                      />
                                                    </div>
            }
                                                   
                                                         <a href={`/search?groupDetail=${value?.gname}`} className="bg-secondary pointer p-1 font-xsssss   text-white">   
                                                         <i className="font-xsssss feather-globe btn-round-xsssss bg-primary-gradiant me-1"></i>{value?.gname}  </a>
                                                        {queryParams.get("searchBy")!="race" && queryParams.get("searchBy")!="auction" &&
                                                         <a className="p-1" href={`/dw?uid=${value?.SID}`}>  
                                                          <span className="p-1 rounded-xxxl bg-black text-white font-xsssss"> dw </span> 
                                                          </a>
                                                        }
                                                       
                                                         { (currentUser?.lead===8 ||  (currentUser?.lead>0 && value?.gname===currentUser?.gname )) && (queryParams.get("searchBy")!="dwTop50" && queryParams.get("searchBy")!="dwTop10")  && (
                                                                  <div >
                                                         <PopupState variant="popover" popupId="demo-popup-menu">
                                                                {(popupState) => (
                                                                  <React.Fragment>
                                                                    <Button variant="contained" {...bindTrigger(popupState)}>
                                                                      <i className="feather-settings"></i>
                                                                    </Button>
                                                                    <Menu {...bindMenu(popupState)}>
                                                                    <MenuItem onClick={()=>{popupState.close();updateaTitle(value?.SID,'Co-owner')}}>Co-owner</MenuItem>
                                                                      <MenuItem onClick={()=>{popupState.close();updateaTitle(value?.SID,'Vocalist')}}>Vocalist</MenuItem>                                                                     
                                                                      <MenuItem onClick={()=>{popupState.close();updateaTitle(value?.SID,'Main Vocalist')}}>Main Vocalist</MenuItem>                                                                     
                                                                      <MenuItem onClick={()=>{popupState.close();updateaTitle(value?.SID,'Prince')}}>Prince</MenuItem>                                                                     
                                                                      <MenuItem onClick={()=>{popupState.close();updateaTitle(value?.SID,'Princess')}}>Princess</MenuItem>                                                                     
                                                                      <MenuItem onClick={()=>{popupState.close();updateaTitle(value?.SID,'Tasnsen')}}>Tansen</MenuItem>                                                                     
                                                                      <MenuItem onClick={()=>{popupState.close();updateaTitle(value?.SID,'None')}}>Rising Star</MenuItem>                                                                                                          
                                                                      <MenuItem onClick={()=>{popupState.close();updateaTitle(value?.SID,'None')}}>None</MenuItem>     

                                                                    </Menu>
                                                                  </React.Fragment>
                                                                )}
                                                              </PopupState>
                                                                </div>
                                                              )

                                                        }  
                                         
                                                    <div className="fw-500 font-xssss text-grey-500 mt-0 mb-0">SID: {value?.sid}</div>
                                                    <div className="fw-500 font-xsssss text-green mt-0 mb-0">GSC: ₹ {value?.total}</div>
                                                    { queryParams.get("searchBy")!="top10B" && queryParams.get("searchBy")!="top10D" &&
                                                    <div className="fw-500 font-xsssss text-blue mt-0 mb-0 p-1 feather-zap strong">{value?.activity}</div>
                                                }
                                                    { queryParams.get("searchBy")!="race" && queryParams.get("searchBy")!="auction" && queryParams.get("searchBy")!="top10B" && queryParams.get("searchBy")!="top10D" && <>
                                                    <div className="fw-500 font-xsssss text-blue mt-0 mb-0 p-1 feather-bar-chart-2  strong">{value?.monthly_points}</div>
                                                   
                                                    <div className="fw-500 font-xsssss text-red mt-0 mb-0 feather-dollar-sign">{value?.dwcost}</div>
                                                    <div className="fw-500 font-xsssss text-blue mt-0 mb-0">DWD: {value?.daily_dw}</div>
                                                    <div className="fw-500 font-xsssss text-red mt-0 mb-0">DWM: {value?.monthly_dw}</div>
                                                    </>
                                                  }

                                                  {(queryParams.get("searchBy")==="top10B" || queryParams.get("searchBy")=="top10D") &&
                                                      <div className="fw-500 font-xsssss text-blue mt-0 mb-0">Quiz Points: {value?.quiz}</div>
                                                  }
                                                   
                                                {queryParams.get("searchBy")!="dwTop50" && queryParams.get("searchBy")!="dwTop10" && queryParams.get("searchBy")!="race" && queryParams.get("searchBy")!="auction" &&
                                                    <div className="fw-500 font-xssss text-red mt-0 mb-3 strong "> <div className="bg-mini-gradiant text-white p-1">
                                                    <div className="bg-primary-gradiant text-white p-1">  {value?.title==='Co-owner'?'':value?.title}</div>
                                                    
                                                    {value?.lead===1?(value?.title==='Co-owner'?value?.title:'Planet Lead'):(value?.lead===2 || value?.lead===3?'Planet Owner':(value?.lead===8?'Founder':value?.lead===7?'Co-Founder':'Proud Member'))}</div>        
                                                   </div>
                                                      }
                                                    {/* <a href={`/userpage?uid=${value?.uid}`} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">Profile</a> */}
                                                    { currentUser?.lead>0  && queryParams.get("searchBy")!="dwTop50" && queryParams.get("searchBy")!="dwTop10" &&  queryParams.get("searchBy")!="dw" && queryParams.get("searchBy")!="auction" &&  queryParams.get("searchBy")!="race" &&
                                                      
                                                    <a onClick={() => adduser(value?.gname==='General'?'Add':'remove',value?.SID)} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-orange font-xsssss fw-700 ls-lg text-white">{value?.gname==='General'?'Add':'Remove'}</a>
                                                    
                                                
                                                    } 
                                                    {currentUser?.SID===value?.SID && queryParams.get("searchBy")=="race" && 

                                                    <a onClick={() => joinrace('exit')} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-orange font-xsssss fw-700 ls-lg text-white">Exit</a>

                                                    }
                                                    { queryParams.get("searchBy")=="auction" && <>
                                                    <div className="fw-500 font-xsssss text-blue strong mt-0 mb-0">Hire Bet: {value?.auction}</div>
                                                    <div className="fw-500 font-xsssss text-blue strong mt-0 mb-0">Transfer Bet: {value?.transferCost}</div>
                                                    <div className="fw-500 font-xsssss text-red mt-0 strong mb-0 p-2">Current Owner: {value?.powner} </div>
                                                    {value?.opic &&
                                                    <Link to={`/userpage?uid=${value?.osid}`}>
                                                   
                                                    
                                                    <Chip  color="primary" style={{backgroundColor:'green'}} component="a" href={'sponsor?stype=game&pid=0&sname=dw'} clickable  
                                                    avatar={<Avatar src={value?.opic?.includes('googleusercontent')?value?.opic:
                                                                  cdn + "users/"+
                                                    value?.opic}>$</Avatar>} label= { 'Sponsor' }  />                                                          
                                                    
                                                   
                                                    </Link>
                                                   }
                                                     
                                                  
                                                    {value?.otransferpic &&
                                                    <Link to={`/userpage?uid=${value?.otransfer}`}>
                                                   
                                                    
                                                    <Chip  color="primary" style={{backgroundColor:'green'}} component="a" href={'sponsor?stype=game&pid=0&sname=dw'} clickable  
                                                    avatar={<Avatar src={value?.otransferpic?.includes('googleusercontent')?value?.otransferpic:
                                                                  cdn + "users/"+
                                                    value?.otransferpic}>$</Avatar>} label= { 'Buyer' }  />                                                          
                                                    
                                                    
                                                    </Link>
                                                   }
                                                     <div><hr></hr></div>
                                                     {isBefore1530() &&
                                                        <div>
                                                          {currentUser?.SID!=value?.SID &&
                                                        <a onClick={() => auctionUpdate(value?.SID,value?.auction>0?value?.auction+1:3,'hire')} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-mini-gradiant font-xsssss fw-700 ls-lg text-white">Hire</a>
                                                          }
                                                        {currentUser?.SID===value?.SID && !value?.allow &&
                                                        <a onClick={() => auctionUpdate(value?.SID,0,'allow')} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-secondary font-xsssss fw-700 ls-lg text-white">Sell Points</a> 
                                                        }
                                                        {currentUser?.SID!=value?.SID && value?.allow &&
                                                        <a onClick={() => auctionUpdate(value?.SID,value?.transferCost>0?value?.transferCost+1:3,'transfer')} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-mini-gradiant font-xsssss fw-700 ls-lg text-white">Buy</a> 
                                                        }
                                                       
                                                        </div> 
                                                     }
                                                   
                                                         </>
                                                         
                                                    }
 

 { currentUser?.lead===8 && queryParams.get("searchBy")!="auction" &&
  <a onClick={() => banUser(value?.SID,value?.uname)} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-red-gradiant font-xsssss fw-700 ls-lg text-white">Ban</a>
}
{/* <a onClick={() => signDW(value?.uname,value?.SID,value?.dwcost)} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-secondary font-xsssss fw-700 ls-lg text-white">Sign</a> */}

    { currentUser?.SID!= value?.SID && value?.signstatus<10 && queryParams.get("searchBy")!="race" && queryParams.get("searchBy")!="auction" && !loading ? (
      <button   onClick={() => signDW(value?.uname,value?.SID,value?.dwcost)} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-secondary font-xsssss fw-700 ls-lg text-white"> Sign</button>
    ):(
    '')}
                                                  {/* <a href={`/dw?uid=${value?.SID}`}>  
                                                       <span className="p-3 rounded-xl m-1 bg-black text-white font-xsssss strong"> dw </span> 
                                                          </a> */}

                                                </div>
                                            </div>
                                        </div> 
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="font-xssss text-red p-2 text-center">
                      {queryParams.get("searchBy")!="dwTop50" && queryParams.get("searchBy")!="dwTop10" && queryParams.get("searchBy")!="race" &&
                    <a onClick={() => getSingers()}
                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block  bg-current font-xsssss fw-700 ls-lg text-white"> 
                    {singers?.length>0? "More Members":"Reset"} </a>
                      }
                   
                       </div>
                </div>
                
             

            </Fragment>
        );
    }


