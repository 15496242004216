import React, { useContext, useEffect, useState, useCallback } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import relativeTime from "dayjs/plugin/relativeTime";
import { AuthContext } from "../context/authContext";
import postService from "../services/posts.service";
import alertService from '../services/sweetAlert';
import profileService from "../services/profile.service";
import { RWebShare } from "react-web-share";
import Avatar from '@mui/material/Avatar';
import Avatars from "./Avatars";
import Chip from '@mui/material/Chip';
import { Link } from "react-router-dom";
import { right } from "@cloudinary/url-gen/qualifiers/textAlignment";


dayjs.extend(relativeTime);
var music = new Audio(null);
var song = new Audio(null);
//const context = new AudioContext();
//const source = context.createBufferSource();

export default function Postview({
  giftItem,
  setGift,
  user,
  time,
  des,
  avater,
  postimage,
  postvideo,
  id,
  item,
  rid,
  refname,
  refuser,
  refpic,
  reftext
}) {
  const navigate = useNavigate();
  const relativeTimeString = dayjs(time).fromNow();
  const { currentUser } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [userReaction, setUserReaction] = useState("");
  const [likes, setLikes] = useState(0);
  const [love, setLove] = useState(0);
  const [comments, setComments] = useState(0);
  const [postReactions, setPostReactions] = useState([]);
  const [exps, setExps] = useState(0);
  const [playing, setPlaying] = useState(null);
  const targetDiv = "target-div";
  const cdn = 'https://files.geetsuhane.com/';
  const context = new AudioContext();
  const [itemid,setItemid] = useState(null);
const source = context.createBufferSource();


  //  console.log(item)

  const getExps = async (i) => {     
    //console.log(i)
    var exps = await profileService.getResult({
        qry: `CALL sp_getExps(${i});`
    }); 
    //console.log('totalExps'+exps?.[0][0]?.totalExps);
    //setExps(exps?.[0][0]?.totalExps);
    if(item?.id===i) {
      item.stars=exps?.[0][0]?.totalExps;

    }
    //return exps?.[0][0]?.totalExps;
   
  }
//console.log(item.mediaType)
  // function parseJSONOrArray(str) {
  //   try {
  //     //console.log(str)
  //     const parsed = JSON.parse(str);
  //     if (Array.isArray(parsed)) {
  //       return parsed;
  //     } else {
  //       return Array.isArray(str) ? str : [];
  //     }
     
  //   } catch (error) {
  //     return [];
  //   }
  // }

  const updateStats = async(pid) => {
    //console.log('called');
    if(currentUser) {
      var res = await profileService.getResult({
        qry: `CALL sp_insertShare(${pid},'${currentUser.id}') `
    });
    }
  }
 
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const deletePostAction = async () => {
    //console.log('hi');
    const response = await postService.deletePost(rid?rid:id);

    //console.log(item);
    if (response) {
      setIsDeleted(true);
      setIsOpen(false);
    }
  };

  

  const sendGift = (item,userpic,user,uid) => {
   
    setItemid(item);
      setGift();
      localStorage.setItem("item", item);
      //localStorage.setItem("giftuser", usr);
      localStorage.setItem("userpic", userpic);
      localStorage.setItem("user", user);
      localStorage.setItem("giftsource", 'post');
      localStorage.setItem("uid", uid);
    // console.log('item'+item);
    // console.log('user'+usr);
    // console.log('userpic'+userpic);
    getExps(item);
 
  }

  async function fetchAudio(name) {
    try {
      const context = new AudioContext();
      
      let rsvp = await fetch(`${name}`);
      return context.decodeAudioData(await rsvp.arrayBuffer()); // returns a Promise, buffer is arg for .then((arg) => {})
    } catch (err) {
      console.log(
        `Unable to fetch the audio file: ${name} Error: ${err.message}`,
      );
    }
  }



  const playSong = async(pid) => {
    // console.log(playing);
    try{
        if( playing===true) {
          //music.pause();
          source.pause();
          setPlaying(false);
          return;
        }
        setPlaying(true)
        var songs =  profileService.getResult({
          qry: `CALL sp_getMusic(${pid})`
        }).then(songs => {
           //music = new Audio(songs?.[0][0]?.musicUrl);
          //song = new Audio(cdn+'users/'+ songs?.[0][0]?.songUrl);
          fetchAudio(cdn+'users/'+ songs?.[0][0]?.songUrl).then(buffer => source.buffer=buffer);
          const biquadFilter = context.createBiquadFilter();
         biquadFilter.type = 'lowshelf';
         // biquadFilter.frequency.setValueAtTime(200, context.currentTime + 1);
          
        //console.log(audioBuffer)				
       

        source.connect(biquadFilter);
        biquadFilter.connect(context.destination);

        source.start();
        //music.play();
        //song.play();
        setPlaying(true)

        source.addEventListener("ended", function(){
          source.currentTime = 0;
          //music.pause();
          source.pause();
          console.log("ended");
          setPlaying(false)
        });

        }
          
      )
    } catch(ex) {
      console.log(ex);
      alertService.info(
        'Error',
        'Apologies, unable to play this song');
        setPlaying(false);
    }
   
  }

  const pinPost = async(pid) => {
    let msg;
    alertService.alertInput(
      'Feature this post'
    ).then(text => {
        if(text) {
          if(currentUser) {
            var res =  profileService.getResult({
              qry: `CALL sp_insertFeatured('${currentUser.id}',${pid},'${text}') `
          });
          } else {
            navigate("/login");
          }
        }
     }
    )
     
  }

  const reactToPost = async (reaction) => {

    if(!currentUser)   navigate("/login");
   
      var updateLikes =  profileService.getResult({
        qry: `CALL sp_updateLikes('${currentUser?.id}', ${id})`
      }).then( ret => {
        // console.log(ret)
        if( ret?.[0][0]?.res===1) {
          setLove(love+1);
        } else {
          alertService.error('Like', 'You already liked this post');
        }
      } 
    )
     
    // if (currentUser?.id) {
    //   const response = await postService.reactToPost({
    //     postId: id,
    //     userId: currentUser?.id,
    //     reaction,
    //   });

      // var updateLikes =  profileService.getResult({
      //   qry: `update posts p set p.likes=p.likes+1 where p.id =(${id})`
      // })
 
  
  };

  // const queryParams = new URLSearchParams(window.location.search)
  // const postId = queryParams.get("postId")
  const highlightPattern = (text) => {
    //if(text?.includes('/recording')) return;
    //console.log(text)
    const pattern = '\#(.*?)\#';
    const splitText = text?.split(pattern);
    //console.log(splitText)
    if (splitText?.length ==0 || !text) 
      return text;
    else {
      const matches = text?.match(pattern);
      //console.log(matches);
      const clink = `/moments?search=${matches?.[1]}`;
      return text?.replace(matches?.[0],`<Link to=${clink}>`+matches?.[0]+'</Link>')
     
    } 
  }

  useEffect(() => {
    // getExps(item.id);

    if (item ) {
     // console.log(item)
     
      // const arr = parseJSONOrArray(item?.reactions);
      // setPostReactions(arr);

      //  if(arr.length>0) {
      //   setLikes(arr.filter((item) => item.reaction === "like").length)
      //   setLove(arr.filter((item) => item.reaction === "love").length)
      //  } else {
      //   setLikes(item?.reactions.filter((item) => item.reaction === "like").length)
      //   setLove(item?.reactions.filter((item) => item.reaction === "love").length)
        
      //  }
      setLove(item?.likes);
      var commentsCount= item.commentsc;
      if(itemid===item?.id) {
        getExps(item?.id);
      }
    
      try{
        setComments(commentsCount>0?commentsCount:"")
      } catch (e) {

      }
    

     
      // setUserReaction(
      //   arr
      //     .filter((item) => item.userId)
      //     .find((item) => item.userId === currentUser?.id)?.reaction
      // );
    }
  }, [currentUser,giftItem]);
  //commentsFn();
  return (
    <React.Fragment>
      {!isDeleted ? (
        <div  className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3" >
        

      {rid && <div>
      <div className="card-body p-0 d-flex position-relative">
          <Link  to={`/userpage?uid=${refuser}`}>
            <figure className="avatar me-3">
              {refpic ? (
                   <Avatar alt="Planets"  sx={{ width: 112, height: 112 }} 
                   src={refpic}
                   />
                // <img
                //   src={refpic}
                //   alt="avater"
                //   className="shadow-sm rounded-circle w45"
                // />
              ) : (
                <div className="align-items-center bg-grey d-flex justify-content-center rounded-img-sm">
                  <span className="ti-user font-sm"></span>
                </div>
              )}
            </figure></Link> <Link  to={`/userpage?uid=${refuser}`}>
            <h4 className="fw-700 text-grey-900 font-xssss mt-1">
              {" "}
              {refname}{" "}
              <span className="font-xssss fw-500 mt-1 lh-3 text-grey-500">
                {" Featured this post "}
              
              </span>
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-blue-500" dangerouslySetInnerHTML={{ __html: highlightPattern(reftext?reftext:'') }}></span>
            </h4></Link> <div></div>
           
     </div>

        </div> }
        {item?.status!=99 && 
          <div className="card-body p-0 d-flex position-relative">
         
          <Link  to={`/userpage?uid=${item?.sid}`}>
            <figure className="avatar me-3">
              {avater ? (
                  <Avatar alt={user}  sx={{ width: 56, height: 56 }} 
                  src={avater}
                  />
                // <img
                //   src={avater}
                //   alt="avater"
                //   className="shadow-sm rounded-circle w45"
                // />
              ) : (
                <div className="align-items-center bg-grey d-flex justify-content-center rounded-img-sm">
                  <span className="ti-user font-sm"></span>
                </div>
              )}
            </figure></Link> <Link  to={`/userpage?uid=${item?.sid}`}>
            <h4 className="fw-700 text-grey-900 font-xssss mt-1">
              {" "}
              {user}{" "}
              {item?.vip===1 &&
             <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"><img src="https://files.geetsuhane.com/icons/vvip-8.gif"></img></span>

              }

              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                {" "}
                {relativeTimeString}
              </span>
              
            </h4></Link> 
            
            {(item?.userId === currentUser?.id || currentUser?.SID === "008")
               && (
                <React.Fragment>
                  <div
                    className={`ms-auto pointer ${isOpen ? " show" : ""}`}
                    id="dropdownMenu4"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={toggleOpen}
                  >
                    <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i>
                  </div>
                  <div
                    className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg  ${
                      isOpen ? " show" : ""
                    }`}
                    aria-labelledby="dropdownMenu4"
                  >
                    <div
                      onClick={ deletePostAction}
                      className="card-body p-0 d-flex"
                    >
                      <i className="feather-trash text-grey-500 me-3 font-lg"></i>
                      <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">
                        Delete Post{" "}
                        <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">
                          Delete Post
                        </span>
                      </h4>
                    </div>
                  </div>
                </React.Fragment>
              )}
          </div> }
          {postvideo  ? (
            <div  className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die pointer"  onClick={()=>{
              //localStorage.setItem('postId',item.id)
              navigate(currentUser?.id ? `/comments?&postId=${item.id}` : "/login")
            }}>
              {/* <a href="/defaultvideo" className="video-btn">
                <video autoPlay loop className="float-right w-100">
                  <source src={`${postvideo}`} type="video/mp4" />
                </video>
              </a> */}
              <video
                src={postvideo + "#t=0.001"}
                type="video/mp4"
                width="100%"
                height="100%"
                controls
              ></video>
            </div>
          ) : (
            ""
          )}

          <div className="card-body p-0 me-lg-5">
            <div className="fw-500 text-black-500 lh-26 font-xsss w-100 mb-2">
              { item?.mediaType==='gif'?
              <img src={des}></img>:
              <div dangerouslySetInnerHTML={{ __html: highlightPattern(des) }}></div>
              }
            
              { item?.mediaType==='song' &&
              <div className="container">
              
              <div onClick={() => playSong(item?.id)}>
                  <img 
                  src={avater} 
                  className="feather-play "
                  width="100%"
                  height="100%" 
                  
                  />
               <i className={`btn ${playing?'feather-pause':'feather-youtube'}  text-white font-xxl`}></i>
                            
              </div>
             {/* <center>
              <audio  controls>
              <source src={item?cdn+'users/'+item?.mediaUrl:null} type="audio/mp3"></source>
              </audio>
              </center> */}
             
              </div>
              
              }
              {/* <a href="/defaultvideo" className="fw-600 text-primary ms-2">
                {des?.length > 100 ? "See more" : ""}
              </a> */}
            </div>
          </div>
          {item?.mediaType==='Youtube' &&
         <div className="card-body d-block p-0 mb-3">
                  <iframe width={"100%"} height={"390px"}
        src={`https://www.youtube.com/embed/${item?.mediaUrl}`} allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen>
        </iframe>
          </div>
        }  
           {item?.mediaType==='Insta' &&
              <div className="card-body d-block p-0 mb-3">
               <iframe width={"100%"} height={"390px"}
              src={`${item?.mediaUrl}`} allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen>
              </iframe>
                </div>
         }  
          {postimage?.length ? (
            <div className="card-body d-block p-0 mb-3 pointer"  onClick={()=>{
              //localStorage.setItem('postId',item.id)
              navigate(currentUser?.id ? `/comments?&postId=${item.id}` : "/login")
            }}>
              <div className="row ps-2 pe-2">
                <div className="col-sm-12 p-1">
                 {item?.status==99 ?
                 <div className="box-shadow: 0 0 0 250px #522d5b">
                  <div   style={{position:"relative", backgroundImage:`url("https://files.geetsuhane.com/home/hbady.gif")`,backgroundRepeat:"no-repeat",backgroundPosition:"center", height: "155px"}}  >  </div>
                  <div className="text-center">
                    <img src={postimage} className="text-center rounded-3 w-75" alt={item?item.text:"post"} />    
             
                  </div>
                 </div>
                 : 
                  <img src={postimage} className="rounded-3 w-100" alt={item?item.text:"post"} />    
                 }             
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div > 
          
          <Chip  color="primary" style={{backgroundColor:'green',float:'left'}} component="a" href={'sponsor?stype=post&pid='+item?.id} clickable  avatar={<Avatar >$</Avatar>} label= { 'Sponsor' }  />  
          {item?.sponsor_pics &&
            <Avatars props={item?.sponsor_pics}  />
          }
      
          </div>
          <div className="card-body d-flex p-0">
            <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2">
              {/* <i
                onClick={() => {
                  reactToPost("like");
                }}
                className={`feather-thumbs-up text-white bg-primary-gradiant me-1 btn-round-xs font-xss ${
                  userReaction === "like" && "reacted"
                }`}
              ></i>
              {likes!=0?likes+37:"29"} <span style={{marginLeft:"10px"}}></span> */}
              <i
                onClick={() => {
                  reactToPost("love");
                }}
                className={`feather-heart text-white bg-red-gradiant me-2 btn-round-xs font-xss  ${
                  userReaction === "love" && "reacted"
                }`}
              ></i> 

              

              {/* {postReactions.filter((item) => item.reaction === "love")
                .length || ""} */} {love!=0?love:""} <span style={{marginLeft:"10px"}}></span>
            </div>
            {/* <div className={`emoji-wrap pointer`}>
              <ul className="emojis list-inline mb-0">
                <li className="emoji list-inline-item">
                  <i className="em em---1"></i>{" "}
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-angry"></i>
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-anguished"></i>{" "}
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-astonished"></i>{" "}
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-blush"></i>
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-clap"></i>
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-cry"></i>
                </li>
                <li className="emoji list-inline-item">
                  <i className="em em-full_moon_with_face"></i>
                </li>
              </ul>
            </div> */}
            <div
            onClick={()=>{
              //localStorage.setItem('postId',item.id)
              navigate(currentUser?.id ? `/comments?&postId=${item.id}` : "/login")
            }}
              className="d-flex cursor-pointer align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"
            >
              <i className="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i>
              <span className="d-none-xss"> </span> {comments!=0?comments:""}
            </div>
            {item?.status!=99 && 
            <>
            
            <div  className="d-flex cursor-pointer align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"
            style={{paddingLeft:"10px"}}  onClick={()=>{sendGift(item.id,avater,user, item.userId)}}   >     <i className="feather-gift text-grey-900 font-sm btn-round-md bg-greylight"></i></div>
              <div className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"> { item?.stars}</div>
            </>}
            {currentUser?.lead>0 &&
              <div onClick={() => pinPost(item?.id)} className="d-flex cursor-pointer align-items-center fw-600 text-grey-900 text-dark lh-26 font-xsssss">
              <i className="feather-trending-up text-grey-900 text-dark btn-round-sm font-lg"></i>
              </div>
             
            }
            <div
              className={`pointer ms-auto d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss`}
              id={`dropdownMenu${id}`}
              data-bs-toggle="dropdown"
              aria-expanded="false"
              // onClick={()=>{openShare('Sharing')}}
            >
              {/* <i className="feather-share text-grey-900 text-dark btn-round-sm font-lg"></i> */}
             
              <RWebShare
                data={{
                    text: user +''+ item?.text.replace(/<[^>]+>/g, ''),
                    url: "https://geetsuhane.com/comments?&postId="+item?.id,
                    title: "Link share",
                }}
                onClick={() => updateStats(item?.id)}
            >
                 <i className="feather-share-2 text-grey-900 text-dark btn-round-sm font-lg"></i>
                 
            </RWebShare>
            </div>
            <div
              className={`dropdown-menu dropdown-menu-end p-4 rounded-xxl border-0 shadow-lg right-0`}
              aria-labelledby={`dropdownMenu${id}`}
            >
              <h4 className="fw-700 font-xss text-grey-900 d-flex align-items-center">
                Share{" "}
                <i className="feather-x ms-auto font-xssss btn-round-xs bg-greylight text-grey-900 me-2"></i>
              </h4>
              
              {/* <div className="card-body p-0 d-flex">
                <ul className="d-flex align-items-center justify-content-between mt-2">
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-facebook">
                      <i className="font-xs ti-facebook text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-twiiter">
                      <i className="font-xs ti-twitter-alt text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-linkedin">
                      <i className="font-xs ti-linkedin text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-instagram">
                      <i className="font-xs ti-instagram text-white"></i>
                    </span>
                  </li>
                  <li>
                    <span className="btn-round-lg pointer bg-pinterest">
                      <i className="font-xs ti-pinterest text-white"></i>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="card-body p-0 d-flex">
                <ul className="d-flex align-items-center justify-content-between mt-2">
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-tumblr">
                      <i className="font-xs ti-tumblr text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-youtube">
                      <i className="font-xs ti-youtube text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-flicker">
                      <i className="font-xs ti-flickr text-white"></i>
                    </span>
                  </li>
                  <li className="me-1">
                    <span className="btn-round-lg pointer bg-black">
                      <i className="font-xs ti-vimeo-alt text-white"></i>
                    </span>
                  </li>
                  <li>
                    <span className="btn-round-lg pointer bg-whatsup">
                      <i className="font-xs feather-phone text-white"></i>
                    </span>
                  </li>
                </ul>
              </div>
              <h4 className="fw-700 font-xssss mt-4 text-grey-500 d-flex align-items-center mb-3">
                Copy Link
              </h4> */}
              {/* <i className="feather-copy position-absolute right-35 mt-3 font-xs text-grey-500"></i>
              <input
                type="text"
                placeholder="https://socia.be/1rGxjoJKVF0"
                className="bg-grey text-grey-500 font-xssss border-0 lh-32 p-2 font-xssss fw-600 rounded-3 w-100 theme-dark-bg"
              /> */}
            </div>
          </div>

  
          
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  );
}

export const Postview1 = React.memo(Postview);