import React, { useState, useEffect } from 'react';

const ResetCountdown = (props) => {
  const [timeLeft, setTimeLeft] = useState('');


  const isBefore1530 = () => {
    const now = new Date();
    const currentHour = now.getUTCHours();
    // Check if current time is less than 15:30
    return currentHour < 16;
  };

  useEffect(() => {
    const calculateTimeLeft = () => {
      const currentTime = new Date();
      const currentUTC = new Date(currentTime.toUTCString());
      var nextResetTimes =null;
      if(props.props==='auction') {
          nextResetTimes = [
            new Date(currentUTC.setUTCHours(16, 0, 0, 0))];  // 2 AM UTC
        } else {
       nextResetTimes = [
        new Date(currentUTC.setUTCHours(2, 0, 0, 0)),  // 2 AM UTC
        new Date(currentUTC.setUTCHours(16, 0, 0, 0))  // 4 PM UTC
      ]; }
    

      const now = new Date();
      
      // Get the next reset time after current time
      const nextReset = nextResetTimes.find(time => time > now) || new Date(nextResetTimes[0].setDate(nextResetTimes[0].getDate() + 1));
      
      const timeDifference = nextReset - now;

      // Convert timeDifference to hours, minutes, and seconds
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setTimeLeft(`${hours}h ${minutes}m ${seconds}s`);
    };

    calculateTimeLeft();

    // Update countdown every second
    const intervalId = setInterval(calculateTimeLeft, 1000);

    // Clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='font-xsss strong text-center'>
    {props.props === "auction" 
      ? (isBefore1530() 
        ? `Time left: ${timeLeft}`
        : `Auction is closed now`)
      : `Time left until next reset: ${timeLeft}`}
  </div>
  
  );
};

export default ResetCountdown;
