import React, { useState, useEffect, useRef, useContext } from "react";
import { YIN } from "pitchfinder";
import useSound from "use-sound";
import profileService from "../services/profile.service";
import { AuthContext } from "../context/authContext";

const sargamFrequencies = {
  Sa: 261.63,
  Re: 293.66,
  Ga: 329.63,
  Ma: 349.23,
  Pa: 392.00,
  Dha: 440.00,
  Ni: 493.88,
};

const raags = {
  Sargam: ["Sa", "Re", "Ga", "Ma", "Pa", "Dha", "Ni", "Sa"],
  Bhairavi: ["Sa", "Re (Komal)", "Ga (Komal)", "Ma", "Pa", "Dha (Komal)", "Ni (Komal)", "Sa"],
  Yaman: ["Ni", "Re", "Ga", "Ma (Teevra)", "Pa", "Dha", "Ni", "Sa"],
  Darbaari: ["Sa", "Re (Komal)", "Ga", "Ma", "Pa", "Dha (Komal)", "Ni", "Sa"],
  Pahadi: ["Sa", "Re", "Ga", "Ma", "Pa", "Dha", "Ni", "Sa"],
  Shivaranjini: ["Sa", "Re", "Ga (Komal)", "Ma", "Pa", "Dha", "Ni", "Sa"],
  Bhopali: ["Sa", "Re", "Ga", "Pa", "Dha", "Sa"],
  Malhar: ["Sa", "Re", "Ga", "Ma", "Pa", "Dha", "Ni", "Sa"],
  Khamaj: ["Sa", "Re", "Ga", "Ma", "Pa", "Dha", "Ni (Komal)", "Sa"],
}

const tolerance = 5;
const noteDuration = 3000; // Time per note in milliseconds
const gameDuration = 60; // Game duration in seconds

const VoiceAnalysis = () => {
  const [score, setScore] = useState(0);
  const [currentPitch, setCurrentPitch] = useState(null);
  const [targetNote, setTargetNote] = useState("Sa");
  const [targetPitch, setTargetPitch] = useState(sargamFrequencies["Sa"]);
  const [currentNote, setCurrentNote] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [selectedRaag, setSelectedRaag] = useState("Sargam");
  const [noteSequence, setNoteSequence] = useState(raags["Sargam"]);
  const [remainingTime, setRemainingTime] = useState(gameDuration);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [slidingNotes, setSlidingNotes] = useState([]); // To render sliding notes
  const { currentUser } = useContext(AuthContext);

  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const pitchDetector = YIN({ sampleRate: 44100 });
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const timerRef = useRef(null);
  const noteChangeIntervalRef = useRef(null);

  const [playMusic, { stop: stopMusic }] = useSound(
    "https://files.geetsuhane.com/sound/csharp1.mp3",
    {
      volume: 0.2,
      loop: true,
    }
  );

  useEffect(() => {
    if (currentUser?.SID && isAnalyzing) {
      getScore();
      startAudioStream();
      playMusic();
      startGameTimer();
      startNoteChangeInterval();
    }
    return () => {
      stopAudioStream();
      stopMusic();
      clearInterval(timerRef.current);
      clearInterval(noteChangeIntervalRef.current);
    };
  }, [currentUser, isAnalyzing]);

  const toggleAnalysis = () => {
    if (isAnalyzing) {
      updateScore();
      stopMusic();
    } else {
      playMusic();
    }
    setIsAnalyzing((prev) => !prev);
  };

  const toggleCamera = () => {
    if (isCameraOn) {
      stopCameraStream();
    } else {
      startCameraStream();
    }
    setIsCameraOn((prev) => !prev);
  };

  const startGameTimer = () => {
    setRemainingTime(gameDuration);
    timerRef.current = setInterval(() => {
      setRemainingTime((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          stopAudioStream();
          //alert("Time's up! Final score: " + score);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const startNoteChangeInterval = () => {
    clearInterval(noteChangeIntervalRef.current); // Clear any existing interval
    let currentIndex = 0;

    noteChangeIntervalRef.current = setInterval(() => {
      const nextIndex = (currentIndex + 1) % noteSequence.length;
      const nextNote = noteSequence[nextIndex];
      setTargetNote(nextNote);
      setTargetPitch(
        sargamFrequencies[nextNote.replace(/\s\(.*?\)/g, "")] || 0
      );

      // Add the note to the sliding notes
      setSlidingNotes((prev) => [...prev.slice(-4), nextNote]);

      currentIndex = nextIndex; // Update the index
    }, noteDuration);
  };

  const startAudioStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 2048;
      source.connect(analyserRef.current);
      processAudio();
      visualizeWaveform();
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  const startCameraStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    } catch (err) {
      console.error("Error accessing camera:", err);
    }
  };

  const stopCameraStream = () => {
    const stream = videoRef.current?.srcObject;
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const stopAudioStream = () => {
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    setCurrentPitch(null);
    setCurrentNote(null);
    clearCanvas();
  };

  const getScore = async () => {
    try {
      const res = await profileService.getResult({
        qry: `CALL sp_DailyTask('','${currentUser?.SID}','fetch','sargam',0)`,
      });
      setScore(res?.[0][0]?.res || 0);
    } catch (error) {
      console.error("Error fetching total score:", error);
    }
  };

  const updateScore = async () => {
    if (score === 0) return;
    const currentScore = score;
    setScore(0);
    try {
      const res = await profileService.getResult({
        qry: `CALL sp_DailyTask('','${currentUser?.SID}','update','sargam',${currentScore})`,
      });
      console.log("Score updated successfully:", res);
    } catch (error) {
      console.error("Error updating total score:", error);
    }
  };

  const processAudio = () => {
    const bufferLength = analyserRef.current.fftSize;
    const dataArray = new Float32Array(bufferLength);

    const detectPitch = () => {
      analyserRef.current.getFloatTimeDomainData(dataArray);
      const pitch = pitchDetector(dataArray);

      if (pitch && pitch >= 80 && pitch <= 1000) {
        setCurrentPitch(pitch);
        const closestNote = getClosestSargamNote(pitch);
        setCurrentNote(closestNote);
        checkMatchAndAdvance(pitch, closestNote);
      } else {
        setCurrentNote(null);
        setCurrentPitch(null);
      }

      if (isAnalyzing) requestAnimationFrame(detectPitch);
    };

    detectPitch();
  };

  const visualizeWaveform = () => {
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    const bufferLength = analyserRef.current.fftSize;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      analyserRef.current.getByteTimeDomainData(dataArray);
      canvasCtx.fillStyle = "#fff";
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);
      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "#4CAF50";
      canvasCtx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;
        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }
        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();

      if (isAnalyzing) {
        requestAnimationFrame(draw);
      } else {
        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
      }
    };

    draw();
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const canvasCtx = canvas.getContext("2d");
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const checkMatchAndAdvance = (pitch, closestNote) => {
    const difference = Math.abs(pitch - targetPitch);

    if (closestNote === targetNote && difference <= tolerance) {
      setScore((prevScore) => prevScore + 10);
    }
  };

  const getClosestSargamNote = (pitch) => {
    let closestNote = null;
    let minDifference = Infinity;

    for (const [note, frequency] of Object.entries(sargamFrequencies)) {
      const difference = Math.abs(pitch - frequency);
      if (difference < minDifference) {
        minDifference = difference;
        closestNote = note;
      }
    }
    return closestNote;
  };

  const handleRaagChange = (e) => {
    const selected = e.target.value;
    setSelectedRaag(selected);
    setNoteSequence(raags[selected]);
    setTargetNote(raags[selected][0]);
    setTargetPitch(sargamFrequencies[raags[selected][0].replace(/\s\(.*?\)/g, "")] || 0);
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.title}>Voice Analysis</h2>
        <select
          style={styles.select}
          value={selectedRaag}
          onChange={handleRaagChange}
        >
          {Object.keys(raags).map((raag) => (
            <option key={raag} value={raag}>
              {raag}
            </option>
          ))}
        </select>
        <div style={styles.noteContainer}>
          {slidingNotes.map((note, index) => (
            <span
              key={index}
              style={{
                ...styles.slidingNote,
                ...(note === targetNote ? styles.highlightedNote : {}),
                animationDelay: `${index * 1.5}s`,
              }}
            >
              {note}
            </span>
          ))}
        </div>
        <canvas
          ref={canvasRef}
          width="300"
          height="100"
          style={styles.canvas}
        />
        <div style={styles.pitchInfo}>
          <h3>
            Current Pitch:{" "}
            {currentPitch ? `${currentPitch.toFixed(2)} Hz` : "Detecting..."}
          </h3>
          <h3>Current Note: {currentNote || "Detecting..."}</h3>
          <h3>Score: {score}</h3>
          <h3>Remaining Time: {remainingTime} sec</h3>
        </div>
        <div>
          {isCameraOn && (
            <video
              ref={videoRef}
              style={styles.video}
              autoPlay
              playsInline
              muted
            />
          )}
        </div>
        <i
          onClick={toggleAnalysis}
          className={`font-xs text-white text-center ${
            isAnalyzing ? "feather-pause" : "feather-mic"
          } counter btn-round-lg bg-mini-gradiant me-1`}
        ></i>
        <i
          onClick={toggleCamera}
          className={`font-xs text-white text-center ${
            isCameraOn ? "feather-video-off" : "feather-video"
          } counter btn-round-lg bg-primary-gradiant me-1`}
        ></i>
      </div>
    </div>
  );
};


const styles = {
  noteContainer: {
    textAlign: "center",
    marginBottom: "10px",
    overflow: "hidden",
    position: "relative",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  slidingNote: {
    position: "relative",
    animation: "slide 6s linear infinite",
    fontSize: "1em",
    fontWeight: "bold",
    color: "#4CAF50",
    margin: "0 20px",
  },
  highlightedNote: {
    color: "red",
    fontWeight: "bold",
  },
  "@keyframes slide": {
    "0%": { transform: "translateX(100%)" },
    "100%": { transform: "translateX(-100%)" },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
    background: "linear-gradient(135deg, #ff9a9e, #fad0c4, #a18cd1, #fbc2eb)",
    backgroundSize: "400% 400%",
    animation: "gradientShift 15s ease infinite",
  },
  card: {
    background: "rgba(255, 255, 255, 0.9)",
    borderRadius: "10px",
    padding: "30px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    width: "90%",
    maxWidth: "400px",
  },
  title: {
    fontSize: "1.8em",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  select: {
    padding: "10px",
    fontSize: "1em",
    marginBottom: "20px",
  },
  noteContainer: {
    textAlign: "center",
    marginBottom: "10px",
    overflow: "hidden",
    position: "relative",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  slidingNote: {
    position: "relative",
    animation: "slide 6s linear infinite",
    fontSize: "1em",
    fontWeight: "bold",
    color: "#4CAF50",
    margin: "0 20px",
  },
  canvas: {
    margin: "10px 0",
    backgroundColor: "#f0f0f0",
    borderRadius: "5px",
    width: "100%",
    maxWidth: "300px",
    height: "100px",
  },
  video: {
    width: "100%",
    borderRadius: "10px",
    margin: "10px 0",
    filter: "brightness(1.2) contrast(1.1) saturate(1.2)",
  },
  "@keyframes slide": {
    "0%": { transform: "translateX(100%)" },
    "100%": { transform: "translateX(-100%)" },
  },
};

export default VoiceAnalysis;
